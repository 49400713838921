// import images
import Logo from '../src/img/header/logo.svg';

import QuoteImg from '../src/img/testimonial/quote.svg';
import { CgWorkAlt } from "react-icons/cg";
import { FaReact } from "react-icons/fa";
import { LuGraduationCap } from "react-icons/lu";

import { MdEventNote } from "react-icons/md";


//parteners images

import GalleryImg0 from '../src/img/ecole/0.jpg';
import GalleryImg1 from '../src/img/ecole/1.jpg';
import GalleryImg2 from '../src/img/ecole/2.jpg';
import GalleryImg3 from '../src/img/ecole/3.jpg';
import GalleryImg4 from '../src/img/ecole/4.jpg';
import GalleryImg5 from '../src/img/ecole/5.jpg';
import GalleryImg6 from '../src/img/ecole/6.jpg';
import GalleryImg7 from '../src/img/ecole/7.jpg';
import GalleryImg8 from '../src/img/ecole/8.jpg';
import GalleryImg9 from '../src/img/ecole/9.jpg';
import GalleryImg10 from '../src/img/ecole/10.jpg';
import GalleryImg11 from '../src/img/ecole/11.jpg';
import GalleryImg12 from '../src/img/ecole/12.jpg';
import GalleryImg13 from '../src/img/ecole/13.jpg';
import GalleryImg14 from '../src/img/ecole/14.jpg';
import GalleryImg15 from '../src/img/ecole/15.jpg';
import GalleryImg16 from '../src/img/ecole/16.jpg';
import GalleryImg17 from '../src/img/ecole/17.jpg';
import GalleryImg18 from '../src/img/ecole/18.jpg';
import GalleryImg19 from '../src/img/ecole/19.jpg';
import GalleryImg20 from '../src/img/ecole/20.jpg';
import GalleryImg21 from '../src/img/ecole/21.jpg';
import GalleryImg22 from '../src/img/ecole/22.jpg';
import GalleryImg23 from '../src/img/ecole/23.jpg';
import GalleryImg24 from '../src/img/ecole/24.jpg';
import GalleryImg25 from '../src/img/ecole/25.jpg';
import GalleryImg26 from '../src/img/ecole/26.jpg';
import GalleryImg27 from '../src/img/ecole/27.jpg';
import GalleryImg28 from '../src/img/ecole/28.jpg';
import GalleryImg29 from '../src/img/ecole/29.jpg';
import GalleryImg30 from '../src/img/ecole/30.jpg';
import GalleryImg31 from '../src/img/ecole/31.jpg';
import GalleryImg32 from '../src/img/ecole/32.jpg';
import GalleryImg33 from '../src/img/ecole/33.jpg';



//parteners images
import AfdImg from '../src/img/parteners/afd.jpg';
import ForimImg from '../src/img/parteners/forim.jpg';
import CosimImg from '../src/img/parteners/cosim.jpg';
import FondationDevoTeamImg from '../src/img/parteners/devoteam.png';
import GuildeImg from '../src/img/parteners/guilde.png';
import MinistereEtrangeImg from '../src/img/parteners/ministereAffaires.png';
import DepartementRhoneImg from '../src/img/parteners/departementRhone.png';
import TchadImg from '../src/img/parteners/tchad.jpg';

// import icons
import { GrFacebookOption } from 'react-icons/gr';
import { IoMdArrowForward } from 'react-icons/io';
import { FiSend } from 'react-icons/fi';
import { FaPlay, FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import {
  IoLogoInstagram,
  IoLogoPinterest,
  IoLogoTwitter,
  IoLogoYoutube,
} from 'react-icons/io';

export const headerData = {
  logo: Logo,
};


export const partenerData = [

  { href: 'https://www.afd.fr', logo: AfdImg , name:'Agence française de développement (AFD)'},
  { href: 'https://forim.net', logo: ForimImg , name:'Réseau des diasporas solidaires(FORIM)'},
  { href: 'https://france.devoteam.com/fondation-devoteam/', logo: FondationDevoTeamImg , name:'Fondation Devoteam'},
  { href: 'https://www.coordinationsud.org/membre/cosim-rhone-alpes-collectif-des-organisations-de-solidarites-internationales-issues-des-migrations-de-la-region-rhone-alpes/', logo:CosimImg , name:'Collectif des Organisations de Solidarité Issues des Migrations,Rhône-Alpes (COSIM)'},
  { href: 'https://www.agencemicroprojets.org/', logo: GuildeImg , name:'Guilde l’Agence des Micro-Projets'},
  { href: 'https://www.diplomatie.gouv.fr/fr/', logo: MinistereEtrangeImg , name:'ministère de l’Europe et des Affaires étrangères'},
  { href: 'https://www.rhone.fr/', logo: DepartementRhoneImg , name:'Département Rhône'},
  { href: '/', logo: TchadImg , name:'Etat Tchadien'},
  
];

export const navData = {
  items: [
    { href: '/', name: 'Accueil' },
    { href: '#about', name: 'A propos de nous' },
    { href: '#projets', name: 'Projets' },
    { href: '#events', name: 'Activités & Evenements' },
    { href: '#gallery', name: 'Gallerie' },
    { href: '#partners', name: 'Partenaires' },
    { href: '#contact', name: 'Contact' },
    { href: '#donate', name: 'Donation' },
  ],
};

export const socialData = [
  { href: '/', icon: <GrFacebookOption /> },
  /* { href: '/', icon: <IoLogoInstagram /> },
  { href: '/', icon: <IoLogoPinterest /> },
  { href: '/', icon: <IoLogoTwitter /> },
  { href: '/', icon: <IoLogoYoutube /> }, */
];

export const heroData = {
  title: 'APPE - Tchad',
  subtitle:
    '“La pétite enfance et l’accès à l’éducation sont nos priorités.”',
  btnText: 'lire plus',
  btnIcon: <IoMdArrowForward />,
};

export const eventsData = [
  {
    title: "Journée : évènement de rentrée",
    location: "Salle Maryse Bastié (Lyon 8ème)",
    description:
      "Au programme : Présentation de l'avancement du projet,Vente de repas tchadien et français,Loto & Jeux de société,Récolte de matériel de puériculture",
    icon: <MdEventNote/>,
    date: "13 octobre 2013",
  },
  {
    title: "Journée : Le projet « La seconde école » fait son deuxième pas",
    location: "Salle Maryse Bastié (Lyon 8ème)",
    description:
      "Nous vous avons préparé un programme alléchant, de 15h à 3h du matin : projection du film  «Abouna», contes, spectacle de djembé, repas africain et français, soirée dansante.",
    icon: <MdEventNote/>,
    date: "1er juin 2013",
  },
  {
    title: "Assemblée Générale",
    location: "Lyon 8ème",
    description:
      "APPE a tenu son Assemblée Générale dans la salle Maryse Bastié, à Lyon 8ème.L'ordre du jour a permis de présenter les bilans d'activités et financiers de l'Association. Les discussions autour du bilan financier a permis d'éclaircir certains points en lien avec le déroulement du projet.S'en est donc suivi une présentation de la situation actuelle de l'association sur le plan de la recherche de subventions et de l'avancée du projet à Sarh, au Tchad.Enfin, nous avons élu le nouveau bureau, qui maintient catherine TAMYAMBA au poste de présidente et Toussaint HALTHODJIM au poste de trésorier.Un petit pot a cloturé l'Assemblée Générale.",
    icon: <MdEventNote/>,
    date: "5 mai 2013",
  },
  {
    title: "Journée de lancement du projet La seconde école",
    location: "Rockakamahjou (Villeurbanne).",
    description:
      "Au programme : 13h repas, 15h : projection du film Mâ saah-sah, 17h30 : discussion   , contes :  (tout public) ,18h30 : présentation du projet et de l'association, 19h : repas, 20h : contes (tout public), 20h30 : groupe de djembé et soirée dansante (DJ)",
    icon: <MdEventNote/>,
    date: "9 février 2013",
  },
  {
    title: "Pique-Nique ",
    location: "Parc Feyssine ,Lyon",
    description:
      "Vous voulez profiter des beaux jours qui viennent ?Ou vous voulez tout simplement prendre l'air?Rien ne vous empêche de passer un moment de détente au Parc Feyssine surtout qu'il va faire très beau!Rendez-vous donc ce dimanche 17 Juin 2012 à 12h autour d'un barbecue aux saveurs surprises.",
    icon: <MdEventNote/>,
    date: "17 Juin 2012",
  },
  {
    title: "Repas",
    location: "Lyon",
    description:
      "Le repas du 8 avril fût sous le signe de la convivialité comme à l’habitude mais encore plus du fait des activités proposées. En effet, durant l’après midi ceux qui le désiraient ont pu découvrir le jeu de l’awalé. C’est un jeu originaire d’Afrique qui utilise des pions très variables de type « coquillage » ou  bien « graines » et un support constitué de 10 trous. Le jeu a bien marché, certains ont même fait un tournoi. Nous réitérerons cette activité le prochain repas pour les retardataires ! Une tombola sous le thème du « Tchad » a été organisée, 1euros de participation pour « posséder » une terre au Tchad. Quatre gagnants se sont vus recevoir des chocolats de pacques. Et pour les enfants, une chasse aux œufs en chocolat a été organisée à l’occasion de la fête de Pacques. Ce fût une journée réussie, riche en amusements. Nous espérons que le prochain repas verra encore plus de participants !",
    icon: <MdEventNote/>,
    date: "8 avril 2012",
  },
]

export const galleryData = {
  title: 'Crèche et École de Kemkian/Sarh en images',
  btnText: 'View all',
  btnIcon: <IoMdArrowForward />,
  images: [
    {
      src: GalleryImg0,
      original: GalleryImg0,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg1,
      original: GalleryImg1,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg2,
      original: GalleryImg2,
      width: 465,
      height: 540,
    },
    {
      src: GalleryImg3,
      original: GalleryImg3,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg4,
      original: GalleryImg4,
      width: 465,
      height: 540,
    },
    {
      src: GalleryImg5,
      original: GalleryImg5,
      width: 465,
      height: 540,
    },
    {
      src: GalleryImg6,
      original: GalleryImg6,
      width: 464,
      height: 412,
    },
    {
      src: GalleryImg7,
      original: GalleryImg7,
      width: 465,
      height: 540,
    },
    {
      src: GalleryImg8,
      original: GalleryImg8,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg9,
      original: GalleryImg9,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg10,
      original: GalleryImg10,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg11,
      original: GalleryImg11,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg12,
      original: GalleryImg12,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg13,
      original: GalleryImg13,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg14,
      original: GalleryImg14,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg15,
      original: GalleryImg15,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg16,
      original: GalleryImg16,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg17,
      original: GalleryImg17,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg18,
      original: GalleryImg18,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg19,
      original: GalleryImg19,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg20,
      original: GalleryImg20,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg21,
      original: GalleryImg21,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg22,
      original: GalleryImg22,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg23,
      original: GalleryImg23,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg24,
      original: GalleryImg24,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg25,
      original: GalleryImg25,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg26,
      original: GalleryImg26,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg27,
      original: GalleryImg27,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg28,
      original: GalleryImg28,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg29,
      original: GalleryImg29,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg30,
      original: GalleryImg30,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg31,
      original: GalleryImg31,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg32,
      original: GalleryImg32,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg33,
      original: GalleryImg33,
      width: 465,
      height: 412,
    },
  ],
};

export const interviewData = {
  title:
    '“Consider what you desire. Your tattoo artist will never tell you what tattoo to have.”',
  btnText: 'Watch it now',
  btnIcon: <FaPlay />,
};

export const testimonialData = [
  {
    quoteImg: QuoteImg,
    message:
      "The entire team is extremely kind and friendly. They're fantastic. They're great at what they do! And it's unique.They will properly consult with you.",
    name: 'Jack Geoffrey',
    occupation: 'Tattoo Artist',
  },
  {
    quoteImg: QuoteImg,
    message:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam culpa asperiores officia natus dignissimos autem possimus molestias aperiam?',
    name: 'Douglas Hane',
    occupation: 'Tattoo Artist',
  },
];

export const contactData = {
  title: 'Contactez-nous :',
  info: [
    {
      title: 'Siège social APPE',
      subtitle:
        'APPE - Association des Professionnels de la Petite Enfance',
      address: {
        icon: <FaMapMarkerAlt />,
        name: '4 allée George Sand 91300 Massy,France',
      },
      phone: {
        icon: <FaPhoneAlt />,
        number: '+33 06 13 22 27 55',
      },
      email: {
        icon: <FaEnvelope />,
        address: 'association.appe@yahoo.com',
      },
      link: 'Get location',
    },
    {
      title: 'Partenaire local AME',
      subtitle:
          'AME - Association Mère Enfant',
      address: {
        icon: <FaMapMarkerAlt />,
        name: 'Quartier Kemkian, Sarh Tchad',
      },
      phone: {
        icon: <FaPhoneAlt />,
        number: '+235 95 73 94 24 ',
      },
      email: {
        icon: <FaEnvelope />,
        address: 'association.appe@yahoo.com',
      },
      link: 'Get location',
    }
  ],
  form: {
    name: 'Ecrivez votre nom ici',
    email: 'Ecrivez votre email ici',
    message: 'Ecrivez votre message here',
    btnText: 'Envoyer',
  },
};

export const footerData = {
  about: {
    title: 'Siège social',
    subtitle:
      'APPE - Tchad',
    address: {
      icon: <FaMapMarkerAlt />,
      name: '4 allée George Sand 91300 Massy,France',
    },
    phone: {
      icon: <FaPhoneAlt />,
      number: '+33 06 13 22 27 55',
    },
    email: {
      icon: <FaEnvelope />,
      address: 'association.appe@yahoo.com',
    },
  },
  partenerLocal: {
    title: 'Partenaire local',
    subtitle:
      'AME - Tchad',
    address: {
      icon: <FaMapMarkerAlt />,
      name: 'Quartier Kemkian, Sarh Tchad',
    },
    phone: {
      icon: <FaPhoneAlt />,
      number: '+235 95 73 94 24',
    },
    email: {
      icon: <FaEnvelope />,
      address: 'association.appe@yahoo.com',
    },
  },
  links: {
    title: 'Liens utiles',
    items: [
      { href: '/', name: 'Accueil' },
      { href: '#about', name: 'A propos de nous' },
      { href: '#projets', name: 'Projets' },
      { href: '#events', name: ' Activités & Evenements' },
      { href: '#partners', name: 'Partenaires' },
      { href: '#contact', name: 'Contact' },
    ],
  },
  program: {
    title: 'Working Time',
    items: [
      { name: 'Mon - Tue / Appointment' },
      { name: 'Wed - Fri / 10:00 - 9:00pm' },
      { name: 'Sat / 10:00 - 6:00pm' },
      { name: 'Sun / no work on this day' },
    ],
  },
  newsletter: {
    title: 'Newsletter',
    subtitle:
      'Abonnez vous à notre Newsletter.',
    form: {
      placeholder: 'Votre email',
      icon: <FiSend />,
    },
  },
};
