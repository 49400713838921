import { useState } from 'react';

export default function KemkianGallery() {
  // Sample placeholder images with different dimensions for variety
  const images = [
    { id: 1, src: "/ecole/0.jpg", alt: "image 0" },
    { id: 2, src: "/ecole/1.jpg", alt: "image 1" },
    { id: 3, src: "/ecole/2.jpg", alt: "image 2" },
    { id: 4, src: "/ecole/3.jpg", alt: "image 3" },
    { id: 5, src: "/ecole/4.jpg", alt: "image 4" },
    { id: 6, src: "/ecole/5.jpg", alt: "image 5" },
    { id: 7, src: "/ecole/6.jpg", alt: "image 6" },
    { id: 8, src: "/ecole/7.jpg", alt: "image 7" },
    { id: 9, src: "/ecole/8.jpg", alt: "image 8" },
    { id: 10, src: "/ecole/10.jpg", alt: "image 10" },
    { id: 11, src: "/ecole/11.jpg", alt: "image 11" },
    { id: 12, src: "/ecole/12.jpg", alt: "image 12" },
    { id: 13, src: "/ecole/13.jpg", alt: "image 13" },
    { id: 14, src: "/ecole/14.jpg", alt: "image 14" },
    { id: 15, src: "/ecole/15.jpg", alt: "image 15" },
    { id: 16, src: "/ecole/16.jpg", alt: "image 16" },
    { id: 17, src: "/ecole/17.jpg", alt: "image 17" },
    { id: 18, src: "/ecole/18.jpg", alt: "image 18" },
    { id: 19, src: "/ecole/19.jpg", alt: "image 19" },
    { id: 20, src: "/ecole/20.jpg", alt: "image 20" },
    { id: 21, src: "/ecole/21.jpg", alt: "image 21" },
    { id: 22, src: "/ecole/22.jpg", alt: "image 22" },
    { id: 23, src: "/ecole/23.jpg", alt: "image 23" },
    { id: 24, src: "/ecole/24.jpg", alt: "image 24" },
    { id: 25, src: "/ecole/25.jpg", alt: "image 25" },
    { id: 26, src: "/ecole/26.jpg", alt: "image 26" },
    { id: 27, src: "/ecole/27.jpg", alt: "image 27" },
    { id: 28, src: "/ecole/28.jpg", alt: "image 28" },
    { id: 29, src: "/ecole/29.jpg", alt: "image 29" },
    { id: 30, src: "/ecole/30.jpg", alt: "image 30" },
    { id: 31, src: "/ecole/31.jpg", alt: "image 31" },
    { id: 32, src: "/ecole/32.jpg", alt: "image 32" },
    { id: 33, src: "/ecole/33.jpg", alt: "image 33" },
  ];

  const [selectedImage, setSelectedImage] = useState(null);

  const openLightbox = (image) => {
    setSelectedImage(image);
  };

  const closeLightbox = () => {
    setSelectedImage(null);
  };
  return (
    <section id="gallery-kemkian" className=' mb-8 scroll-mt-10'>
      <div className='container mx-auto mb-20'>
      <h3 className='h3 font-bold mb-5'> Crèche et École de Kemkian / Sarh en images</h3>
      {/* Gallery Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {images.map((image) => (
          <div 
            key={image.id} 
            className="overflow-hidden rounded-lg shadow-md cursor-pointer transition-transform duration-300 hover:scale-105"
            onClick={() => openLightbox(image)}
          >
            <img 
              src={image.src} 
              alt={image.alt} 
              className="w-full h-48 object-cover"
            />
          </div>
        ))}
      </div>

      {/* Lightbox */}
      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={closeLightbox}>
          <div className="max-w-4xl max-h-full p-4">
            <img 
              src={selectedImage.src} 
              alt={selectedImage.alt} 
              className="max-h-screen max-w-full object-contain"
            />
          </div>
          <button 
            className="absolute top-4 right-4 text-white text-2xl font-bold"
            onClick={closeLightbox}
          >
            &times;
          </button>
        </div>
      )}

    </div>
    </section>)
}
