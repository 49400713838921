import React from 'react';



    const KemkianEcole = () => {   

  return (


      <section  id="projet-kemkian" className=' mb-8 scroll-mt-28'>
         <div className='container mx-auto mb-20'>
         <h3 className='h3 font-bold mb-5'> École de Kemkian, Sarh, Tchad : Projet Amélioration de conditions de vie en milieux scolaires (2024-2025)</h3>
        
         <p className='h4  mb-2'>Salles de Classe, Toilettes, clôture de séparation et aménagement</p>
        <h4 className="font-bold mb-2">Contexte</h4>

        <p className="mb-4">De 2020 à 2021, grâce aux subventions majoritaires de La GUILDE Agence Micro-projets
        (A.M.P), La Fondation DEVOTEAM et le Mécénat du Groupe BOLLORÉ, notre Association
        des Professionnels de la Petite Enfance (A.P.P.E) a renforcé la capacité d’accueil des primaires
        à l’école de Kemkian, à Sarh au sud du Tchad. En d’autres termes, nous avons construit 2
        bâtiments de 2 salles de classe chacun.</p>

        <p>Grâce aux nouvelles salles de classe et à notre proximité aux populations, les familles ont pu
        inscrire massivement leurs enfants :</p>
        
        <div className="border border-gray-300 p-4 mb-4">
          <h4 className="font-bold mb-2">Évolution des effectifs</h4>
          <ul className="list-disc pl-6">
            <li>2022-2023: 143 élèves (65 filles, 78 garçons)</li>
            <li>2023-2024: 240 élèves (112 filles, 128 garçons)</li>
            <li>2024-2025: 312 élèves inscrits</li>
          </ul>
        </div>
        <p>Cependant, il nous manque encore des salles, surtout une grande classe pour les CM2. L’année
        dernière, nous les avons accueillis dans la petite salle de la maternelle qui sert de bureau du
        directeur. Mais cette année 2024-2025, vu l’effectif, nous avons dû construire un hangar de
        fortune en paille (seko) pour servir de salle de classe pour les CM2.</p>
   
        <p>
        Cette situation précaire nous a poussé à créer le projet « Amélioration de conditions de vie en
        milieux scolaires : Salles de Classe, Toilettes, clôture de séparation et aménagement » dont les
        objectifs spécifiques sont :
        </p>

        <div className="bg-gray-50 p-4 border border-gray-200 mb-10">
                <ol className="list-decimal pl-6">
                    <li>Construire un bâtiment de 2 salles équipées en tables-bancs, chaises et tables.</li>
                    <li>Construire une clôture séparant la maternelle et le primaire. </li>
                    <li>Construction de toilettes : 2 latrines à 2 compartiments avec fosses septiques</li>
                    <li>Ameublement de la maternelle : Tables, chaises, tableaux muraux et séparation en sections</li>
                </ol>
        </div>


        <p className=" font-bold mb-4">Démarrage des travaux (Mars 2025)</p>
        <div className="border-l-4 border-gray-400 pl-4">
          <p className="mb-2">• Cabane de stockage construite</p>
          <p className="mb-2">• Achat et transport des matériaux</p>
          <p className="mb-2">• Fouilles et terrassement</p>
          <p className="mb-2">• Fondations du bâtiment et des latrines</p>
        </div>
        
        <div className="bg-gray-100 p-4 mt-4 border-l-4 border-black">
          <p className="font-bold mb-2">Note importante:</p>
          <p>Les 20 et 21 mars 2025, l'A.P.P.E a envoyé 13.721€ (9.000.000 FCFA) sur le compte de l'Association Mère Enfant de Sarh. Priorité donnée à la construction du bâtiment de 2 salles avant la saison des pluies.</p>
        </div>

        <div className='flex flex-col  text-grey mt-6'>
                                <h3 className='h3 font-bold'>Projet « Agrandissement d'une école dans un quartier défavorisé de SARH au TCHAD.»</h3>
                                <ul className="list-disc ml-10" >
                                    <li><p>2020 - 2022 : initié en septembre 2020, le projet a démarré en avril 2021 et est terminé en octobre 2022.
                                        </p></li>
                                        <li><p>Il a été soutenu financièrement par la Guilde l’Agence des 
                                            Micro-Projets à hauteur de 10.000 €, la Fondation DEVOTEAM S.A (6.000€ : 3.000€ 
                                            en 2021 et 3.000€ en 2022) , le Mécénat du Groupe Bolloré (5.000€) , Apport APPE (5000 euro)
                                            </p>
                                       </li>
                                    <li>
                                            <p>  Réalisation d'un bâtiment de 4 classes en dur et meublé.
                                            </p>
                                    </li>
                                    <li>
                                            <p>  
                                                Ouverture  des classes CE2 et CM1  durant l'année académique 2022-2023
                                            </p>
                                    </li>
                                </ul>
                            </div>
                <div className='flex flex-col mt-10 text-grey'>
                    <h3 className='h3 font-bold'>Projet « La seconde école » au quartier KEMKIAN, ville SARH au TCHAD.»</h3>
                    <ul className="list-disc ml-10" >
                        <li><p>2013 : Ouverture de la Crèche au quartier KEMKIAN.
                                Deux (02) bâtiments (crèche 85 m2 + une salle de cours 60m2) ont été construits au quartier KEMKIAN, quartier très défavorisé à la périphérie de la ville de SARH.
                            </p></li>
                            <li><p>2017: Construction d’un mur d'enceinte (140m de longueur, 65m de largeur et 2,10m de hauteur avec portail et petite porte d'entrée, toujours dans le au quartier KEMKIAN. Cela assure la sécurité des enfants mais aussi des bâtiments.</p></li>
                            <li> <p>2018: Ouverture d’une classe de CP1, au sein du 2nd bâtiment</p></li>
                            <li>  <p>2019: Ouverture d’une classe de CP2 au sein du 2nd bâtiment qui cumule pour l’instant un double niveau CP1/CP1</p></li>
                            <li> <p>2020: Ouverture d’une classe de CE1 au sein du 1er bâtiment de crèche, dans une salle utilisée autrefois comme bureau du directeur et du personnel.</p></li>
                            <li>
                                <p>Le projet a été soutenu financièrement à hauteur de 34641 €  Grâce à plusieurs subventions : <br/>
                                    <ul className="list-none">
                                    <li > Ministère des affaires Etrangères et Européennes France(26000€ )</li>
                                    <li >APPE porteur du projet(3641€)</li>
                                    <li >Département du Rhône(2000€)</li>
                                    <li >Donation Cantonale Ville St Priest(500€) </li>
                                    </ul>
                                </p>
                            </li>
                    </ul>
                    
                </div>
        
        </div>
      </section>)


   }; 
  export default KemkianEcole;