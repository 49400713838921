import React, { useState } from 'react';
import LogoAppe from '../img/header/logoAppe.jpg';
import { Menu, X } from 'lucide-react';

const Header = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  const [isSubmenuGalleryOpen, setIsSubmenuGalleryOpen] = useState(false);
  
  const toggleSubmenu = () => {
    setIsSubmenuOpen(!isSubmenuOpen);
  };

  const toggleSubmenuGallery = () => {
    setIsSubmenuGalleryOpen(!isSubmenuGalleryOpen);
  };

  return (
    <header className="bg-white shadow-md ">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <div className="flex-shrink-0">
            <a href="/" className="text-xl font-bold text-gray-800">
                <div className='flex flex-col justify-center items-center mt-2'>
                  <img src={LogoAppe} className='w-12 h-12 object-contain  ' alt=""/>
                </div>
            </a>
          </div>
          
          {/* Main Navigation */}
          <nav className="hidden md:flex space-x-8">
            <a href="#about" className="text-gray-600 hover:text-gray-900">
               A propos de nous
            </a>
            <div className="relative">
              <button 
                onClick={toggleSubmenu}
                className="text-gray-600 hover:text-gray-900 flex items-center"
              >
                Projets
                <svg 
                  className={`ml-1 h-4 w-4 transition-transform duration-200 ${isSubmenuOpen ? 'rotate-180' : ''}`}
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              
              {/* Submenu */}
              {isSubmenuOpen && (
                <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                  <a href="#projet-kemkian" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    Kemkian SARH
                  </a>
                  <a href="#projet-modele" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    Modélé CANTON
                  </a>
                  
                </div>
              )}
            </div>
            <a href="#events" className="text-gray-600 hover:text-gray-900">
            Activités & Evenements
            </a>
            <div className="relative">
              <button 
                onClick={toggleSubmenuGallery}
                className="text-gray-600 hover:text-gray-900 flex items-center"
              >
                Gallerie
                <svg 
                  className={`ml-1 h-4 w-4 transition-transform duration-200 ${isSubmenuGalleryOpen ? 'rotate-180' : ''}`}
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              
              {/* Submenu */}
              {isSubmenuGalleryOpen && (
                <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                  <a href="#gallery-kemkian" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    Kemkian SARH
                  </a>
                  <a href="#gallery-modele" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    Modélé CANTON
                  </a>
                  
                </div>
              )}
            </div>
            
            <a href="#partners" className="text-gray-600 hover:text-gray-900">
              Partenaires
            </a>
            <a href="#contact" className="text-gray-600 hover:text-gray-900">
              Contact
            </a>
            <a href="#donate" className="text-gray-600 hover:text-gray-900">
              Donation
            </a>
          </nav>
          
          {/* Mobile menu button */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-600 hover:text-primary focus:outline-none"
            >
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
           {/* Mobile Navigation */}
           {isOpen && (
          <div className="md:hidden flex flex-col">
            <div className="px-1 pt-2 pb-3 space-y-1 sm:px-1">
            <nav className="md:hidden flex flex-col">
            <a href="#about" className="text-gray-600 hover:text-gray-900">
               A propos de nous
            </a>
            <div className="relative">
              <button 
                onClick={toggleSubmenu}
                className="text-gray-600 hover:text-gray-900 flex items-center"
              >
                Projets
                <svg 
                  className={`ml-1 h-4 w-4 transition-transform duration-200 ${isSubmenuOpen ? 'rotate-180' : ''}`}
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              
              {/* Submenu */}
              {isSubmenuOpen && (
                <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                  <a href="#projet-kemkian" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    Kemkian SARH
                  </a>
                  <a href="#projet-modele" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    Modélé CANTON
                  </a>
                  
                </div>
              )}
            </div>
            <a href="#events" className="text-gray-600 hover:text-gray-900">
            Activités & Evenements
            </a>
            <div className="relative">
              <button 
                onClick={toggleSubmenuGallery}
                className="text-gray-600 hover:text-gray-900 flex items-center"
              >
                Gallerie
                <svg 
                  className={`ml-1 h-4 w-4 transition-transform duration-200 ${isSubmenuGalleryOpen ? 'rotate-180' : ''}`}
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              
              {/* Submenu */}
              {isSubmenuGalleryOpen && (
                <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                  <a href="#gallery-kemkian" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    Kemkian SARH
                  </a>
                  <a href="#gallery-modele" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    Modélé CANTON
                  </a>
                  
                </div>
              )}
            </div>
            
            <a href="#partners" className="text-gray-600 hover:text-gray-900">
              Partenaires
            </a>
            <a href="#contact" className="text-gray-600 hover:text-gray-900">
              Contact
            </a>
            <a href="#donate" className="text-gray-600 hover:text-gray-900">
              Donation
            </a>
            </nav>
              
            </div>
            <div className='mb-5 md:mb-0'>
            <a
              href="https://www.helloasso.com/associations/association-ronel/formulaires/1" target="_blank"
                    className="rounded-md bg-primary px-10  md:mt-0 py-3 text-sm font-semibold text-white shadow-sm hover:text-white uppercase   "
                    >
              Faire un don
            </a>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;