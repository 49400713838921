import React from 'react';

// import components
import Hero from './components/Hero';
import Header from './components/Header';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Copyright from './components/Copyright';


import Events from "./components/Events";
import Donate from "./components/Donate";
import Partners from "./components/Partners";
import KemkianEcole from './components/KemkianEcole';
import ModeleEcole from './components/ModeleEcole';
import ModeleGallery from './components/ModeleGallery';
import KemkianGallery from './components/KemkianGallery';





const App = () => {
  return (
    <div className='max-w-[1920px] mx-auto overflow-hidden bg-white'>
      <Header />
      <Hero />
      <About />
      <KemkianEcole/>
      <KemkianGallery/>
      <ModeleEcole/>
      <ModeleGallery/>
      <Events/>
      <Partners/>
      <Contact />
      <Donate/>
      <Footer />
      <Copyright />
      {/* <div className='h-[4000px]'></div> */}
    </div>
  );
};

export default App;
