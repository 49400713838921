import React from 'react';

const ModeleEcole = () => {     
  return (
    <section id="projet-modele" className=' mb-8 scroll-mt-28'>
     <div className='container mx-auto mb-20'>
        
            <h3 className='h3 font-bold mb-5'> École de Modélé : Projet « Agrandissement de l'école de Modélé au TCHAD : Construction d'un bâtiment de 2 salles de classes et Réhabilitation de la toiture du bâtiment actuel (3 classes) »</h3>
            <p>Notre projet s’inscrit pleinement dans l'objectif n°4 des Objectifs de Développement Durable. En effet, d’ici à 2030, il faut « faire en sorte que toutes les filles et tous les garçons suivent, sur un pied d’égalité, un cycle complet d’enseignement primaire et secondaire, gratuit et de qualité, les dotant d’acquis véritablement utiles ». En ce moment, les élèves de l’école communautaire de Modélé sont exposés à toute sorte d’intempéries conduisant à « l’école buissonnière ». La réparation de la toiture est nécessaire pour préserver le bâtiment de l’écroulement total. La construction des salles de classe complémentaires dans cette structure permet de lutter la surpopulation en classe et la baisse de niveau.</p>
            <p>Objectifs spécifiques du projet :</p>
            <div className="bg-gray-50 p-4 border border-gray-200 mb-10">
                <ol className="list-decimal pl-6">
                    <li>Construire un bâtiment de 2 classes en dur, cadre propice aux apprentissages quel que soit les conditions météorologiques et permettant de répartir chaque niveau (CP1 au CM2)</li>
                    <li>Créer un pôle éducatif complet, sécurisé et de proximité qui permet de renforcer la motivation des familles à scolariser leurs enfants, en particulier les filles.</li>
                </ol>
            </div>

            <h3 className='h3 font-bold mb-5'> École de Modélé : Alphabétisation Bibliothèque Couture - Formation des femmes et des jeunes filles-mères au Tchad (2025-2027)</h3>
            <p>En 2023, grâce aux subventions conséquentes de LA GUILDE AGENCE MICRO PROJETS soutenant notre projet
            "2023-03-0011", nous avons mené 3 ateliers de sensibilisation en faveur de l'inscription et du maintien des filles à
            l'école de Modélé.</p>
            <p>
            Au cours de ces ateliers, les femmes se sont exprimées. Elles ont dit : "Nous avons honte lorsque nous recevons des
            étrangers et parmi lesquels se trouvent des femmes qui s'expriment en français ou en anglais". Les plus âgées de ces
            femmes regrettent de n'avoir pas encouragé leurs filles à aller à l'école. C'est comme si elles leur ont créé toutes les
            possibilités de vivre le même calvaire qu'elles-mêmes avaient vécu. Elles auraient souhaité que le projet "2023-03-
            0011" eût lieu 10, 15 ou 20 ans en arrière car cela aurait changé leur vie sinon, sur du long terme, celle de leurs filles
            qui sont, aujourd'hui, devenues précocement toutes des jeunes filles-mères...
            </p>
            <p>Le besoin étant réel, nous avons trouvé juste de poursuivre nos efforts grâce projet ABC dont les objectifs sont :</p>
            <div className="bg-gray-50 p-4 border border-gray-200">
                <ol className="list-decimal pl-6">
                    <li>Construction d'une salle pour les cours d'alphabétisation</li>
                    <li>Construction d'une salle de formation en couture</li>
                    <li>Construction d'une bibliothèque</li>
                    <li>Équipement en panneaux solaires</li>
                </ol>
                
                <p className="mt-4"><span className="font-semibold">Budget prévisionnel : </span> 32.000€</p>
            </div>

            <div className='flex flex-col mt-5  text-grey'>
            <h3 className='h3 font-bold' >Projet « Amélioration des conditions de scolarisation en école primaire des filles du village de Modelé/MANDOUL, au Tchad ».</h3>
            <div>
            <p>2019 - 2020: Construction d’un bâtiment (189m2) de 3 classes en dur, d’1 forage d’eau potable et des latrines</p>
            <p>Le projet a été soutenu financièrement par Forum des Organisations Issues de l’Immigration (FORIM/PRA-OSIM),Agence Française de Développement (AFD), Fondation DEVOTEAM à hauteur de 15.000 €. Ces réalisations ont permis de faire de cette école : </p>
            <ul className="list-disc ml-10" >
                <li >Une école de proximité, surtout en faveur des filles.</li>
                <li> Un lieu d' enseignement de qualité  visant à intégrer majoritairement les filles pour qu’elles aient l’opportunité de poursuivre ensuite leurs études au collège.</li>
            </ul>
            </div>
        
        </div>  
    </div> 
    
    </section>)


}; 
export default ModeleEcole;