import { useState } from 'react';

export default function ModeleGallery() {
  const [selectedImage, setSelectedImage] = useState(null);
  
  const images = [
    {
      id: 1,
      title: 'Travaux de rénovation de la toiture',
      description: 'Remplacement de l’actuelle charpente par celle en métallique et tôles galvanisées plus résistances aux fortes pluviométries, vents, orages.',
      src:'/modele/modele_4.jpeg'
    },
    {
      id: 2,
      title: 'Travaux de rénovation de la toiture',
      description: 'Remplacement de l’actuelle charpente par celle en métallique et tôles galvanisées plus résistances aux fortes pluviométries, vents, orages.',
      src:'/modele/modele_5.jpeg'
    },
    {
      id: 3,
      title: 'Début des travaux du bâtiment de 2 salles de classe ',
      description: 'Phase de fouilles et de fondations',
      src:'/modele/modele_2.jpeg'
    },
    {
      id: 4,
      title: 'Construction bâtiment de 2 salles de classe ',
      description: 'Phase élévation',
      src:'/modele/modele_6.jpeg'
    },
    {
      id: 5,
      title: 'Finition du bâtiment de 2 salles de classe',
      description: 'Finition du bâtiment de 2 salles de classe',
        src:'/modele/modele_7.jpeg'
    },
    {
      id: 6,
      title: 'Finition du bâtiment de 2 salles de classe',
      description: 'Finition du bâtiment de 2 salles de classe',
        src:'/modele/modele_1.jpeg'
    }
  ];

  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <section id="gallery-modele" className=' mb-8 scroll-mt-10'>
      <div className='container mx-auto mb-20'>
      <h3 className='h3 font-bold mb-5'> École de Modélé : Déroulement des travaux en images</h3>

        
        {/* Image Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {images.map((image) => (
            <div 
                key={image.id} 
                className="overflow-hidden rounded-lg shadow-lg cursor-pointer transition-transform duration-300 hover:scale-105"
                onClick={() => openModal(image)}
            >
                <img 
                src={image.src} 
                alt={image.title} 
                className="w-full h-48 object-cover"
                />
                <div className="p-4 bg-white">
                <h3 className="text-lg font-semibold">{image.title}</h3>
                <p className="text-sm text-gray-600">{image.description}</p>
                </div>
            </div>
            ))}
        </div>

        {/* Modal for enlarged image */}
        {selectedImage && (
            <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4" onClick={closeModal}>
            <div className="bg-white rounded-lg max-w-3xl w-full" onClick={(e) => e.stopPropagation()}>
                <div className="relative">
                <img 
                    src={selectedImage.src} 
                    alt={selectedImage.title} 
                    className="w-full h-64 md:h-96 object-cover rounded-t-lg"
                />
                <button 
                    className="absolute top-2 right-2 bg-black bg-opacity-50 text-white w-8 h-8 rounded-full flex items-center justify-center"
                    onClick={closeModal}
                >
                    ×
                </button>
                </div>
                <div className="p-4">
                <h3 className="text-xl font-bold">{selectedImage.title}</h3>
                <p className="text-gray-700 mt-2">{selectedImage.description}</p>
                </div>
            </div>
            </div>
        )}
 
    </div>
    </section>)
}
